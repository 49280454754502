import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";

interface LoadingState {
  isLoading: boolean;
  deleteJob: {
    deleted: boolean;
    jobId: string;
  } | null;
  verticalStepWidth: number;
}

const initialState: LoadingState = {
  isLoading: false,
  deleteJob: null,
  verticalStepWidth: 0,
};

export const loadingSlice = createSlice({
  name: "loadingStore",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    handleDeleteJob: (state, action: PayloadAction<{ deleted: boolean; jobId: string } | null>) => {
      state.deleteJob = action.payload;
    },
    setVerticalStepWidth: (state, action: PayloadAction<number>) => {
      state.verticalStepWidth = action.payload;
    },
  },
});

export const { setLoading, handleDeleteJob, setVerticalStepWidth } = loadingSlice.actions;
export default loadingSlice.reducer;
