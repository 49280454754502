import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import { Loader2, Pencil, RefreshCcwDot, ListPlus } from "lucide-react";

import { IJob } from "src/models";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { cn, areArraysEqual } from "src/lib/utils";
import { assessmentCriteriaGenerate, updateAssessmentCriteria } from "src/services/job";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "src/shadcn/ui/table";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/tooltip";

interface Props {
  currentJob: IJob;
  originJob: IJob;
  setCurrentJob: Dispatch<SetStateAction<IJob | null>>;
  onBack: () => void;
}

const cellItem = ({
  isEdit,
  value,
  key,
  index,
  onChangeValue,
}: {
  isEdit: boolean;
  value: string;
  key: string;
  index: number;
  // eslint-disable-next-line no-unused-vars
  onChangeValue: (value: string, key: string, index: number) => void;
}) => {
  return (
    <>
      {isEdit ? (
        <Textarea
          placeholder="Type your message here."
          value={value}
          rows={1}
          onChange={(e) => onChangeValue(e.target.value, key, index)}
        />
      ) : (
        <div>{value}</div>
      )}
    </>
  );
};

const ReviewShortlistingCriteria = ({ currentJob, originJob, setCurrentJob, onBack }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  // states
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGenerateLoading, setIsGenerateLoading] = useState(false);
  const [isRetryGenerateLoading, setIsRetryGenerateLoading] = useState(false);

  // functions
  const handleAssessmentCriteriaGenerate = async () => {
    if (!currentJob?.id) return;

    if (
      currentJob?.assessment_must_have_criteria &&
      currentJob?.assessment_desire_to_have_criteria &&
      currentJob?.assessment_must_have_criteria?.length > 0 &&
      currentJob?.assessment_desire_to_have_criteria?.length > 0
    ) {
      return;
    }

    setIsGenerateLoading(true);
    const res = await assessmentCriteriaGenerate(currentJob.id);
    setIsGenerateLoading(false);

    if (res) {
      setCurrentJob({ ...res });
    }
  };

  const handleTextareaChange = (value: string, key: string, index: number) => {
    const updatedJob = { ...currentJob };

    if (key === "assessment_must_have_criteria") {
      if (
        updatedJob?.assessment_must_have_criteria?.[index] ||
        updatedJob?.assessment_must_have_criteria?.[index] === ""
      ) {
        updatedJob.assessment_must_have_criteria[index] = value;
      }
    } else if (key === "assessment_desire_to_have_criteria") {
      if (
        updatedJob.assessment_desire_to_have_criteria?.[index] ||
        updatedJob.assessment_desire_to_have_criteria?.[index] === ""
      ) {
        updatedJob.assessment_desire_to_have_criteria[index] = value;
      }
    }

    setCurrentJob({ ...updatedJob });
  };

  const handleUpdateAssessmentCriteria = async () => {
    if (
      areArraysEqual(currentJob?.assessment_must_have_criteria || [], originJob?.assessment_must_have_criteria || []) &&
      areArraysEqual(
        currentJob?.assessment_desire_to_have_criteria || [],
        originJob?.assessment_desire_to_have_criteria || [],
      )
    ) {
      navigate(`${location.pathname}?step=4`);
      return;
    }

    setIsLoading(true);
    const res = await updateAssessmentCriteria(
      currentJob.id,
      currentJob.assessment_must_have_criteria || [],
      currentJob.assessment_desire_to_have_criteria || [],
    );
    setIsLoading(false);

    if (res) {
      setCurrentJob({ ...res });
      navigate(`${location.pathname}?step=4`);
    }
  };

  const handleRetryAssessmentGenerate = async () => {
    setIsRetryGenerateLoading(true);
    const res = await assessmentCriteriaGenerate(currentJob.id);
    setIsRetryGenerateLoading(false);

    if (res) {
      setCurrentJob({ ...res });
    }
  };

  const handleAddNewRow = () => {
    const currentJobCopy = { ...currentJob };
    currentJobCopy.assessment_must_have_criteria?.push("");
    currentJobCopy.assessment_desire_to_have_criteria?.push("");
    setCurrentJob({ ...currentJobCopy });
  };

  const handleBack = () => {
    onBack();
  };

  // effects
  useEffect(() => {
    handleAssessmentCriteriaGenerate();
  }, []);

  // memos
  const maxLength = useMemo(() => {
    return Math.max(
      currentJob?.assessment_desire_to_have_criteria?.length || 0,
      currentJob?.assessment_must_have_criteria?.length || 0,
    );
  }, [currentJob]);

  return (
    <div className="grow relative pb-14">
      {/* <h1 className="mb-6 text-lg font-semibold">Assessment criteria</h1> */}

      {isGenerateLoading || isRetryGenerateLoading ? (
        <div className="w-full h-[50vh] flex items-center justify-center">
          <Loader2 className="animate-spin size-7" />
        </div>
      ) : (
        <div className="mb-8 border rounded-md overflow-hidden">
          <Table>
            <TableCaption className="hidden">A list of your recent invoices.</TableCaption>
            <TableHeader className="bg-gray-300">
              <TableRow>
                <TableHead className="font-bold text-black">Must have requirements</TableHead>
                <TableHead className="font-bold text-black">Desirable requirements</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="[&_tr:last-child]:border-b">
              {Array(maxLength)
                .fill(0)
                .map((_, index) => (
                  <TableRow
                    key={`${_}-${index}-item`}
                    className={cn("border-b", currentJob?.assessment_must_have_criteria?.[index] ? "" : "h-8")}
                  >
                    <TableCell className="border-r">
                      {/* {currentJob?.assessment_must_have_criteria?.[index]} */}
                      {cellItem({
                        isEdit,
                        key: "assessment_must_have_criteria",
                        index,
                        value: currentJob?.assessment_must_have_criteria?.[index] || "",
                        onChangeValue: handleTextareaChange,
                      })}
                    </TableCell>
                    <TableCell>
                      {/* {currentJob?.assessment_desire_to_have_criteria?.[index]} */}
                      {cellItem({
                        isEdit,
                        key: "assessment_desire_to_have_criteria",
                        index,
                        value: currentJob?.assessment_desire_to_have_criteria?.[index] || "",
                        onChangeValue: handleTextareaChange,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>
      )}

      <div className="my-4 flex items-center justify-end">
        <span className="text-sm text-black mr-2">Edit criteria</span>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="p-1 h-fit"
                onClick={() => setIsEdit(!isEdit)}
              >
                <Pencil className="size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="dark:text-white text-black text-sm">Edit</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                className="p-1 h-fit"
                onClick={handleAddNewRow}
              >
                <ListPlus className="size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="dark:text-white text-black text-sm">Add new row</TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                disabled={isRetryGenerateLoading}
                className="p-1 h-fit"
                onClick={handleRetryAssessmentGenerate}
              >
                <RefreshCcwDot className="size-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent className="dark:text-white text-black text-sm">Retry</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>

      <div className="mt-6 flex justify-end gap-4 absolute bottom-0 right-0">
        <Button
          variant="outline"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          className="flex items-center gap-2"
          disabled={isLoading}
          onClick={handleUpdateAssessmentCriteria}
        >
          {isLoading && <Loader2 className="animate-spin size-5" />}
          <p>Next Step</p>
        </Button>
      </div>
    </div>
  );
};

export default ReviewShortlistingCriteria;
