import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { Loader2 } from "lucide-react";
import { diffWords } from "diff";
import { zodResolver } from "@hookform/resolvers/zod";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";

import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { setLoading } from "src/store/features/loading";
import { IJob, ITeam } from "src/models";
import { FileUploader } from "src/components/common";
import { jobDescriptionFormSchema } from "src/models/form-schema";
import { addDescriptionToJob, addFileToJob, createJobFirstStep } from "src/services/job";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

interface Props {
  defaultTeam: ITeam | null;
  currentJob: IJob | null;
  setCurrentJob: Dispatch<SetStateAction<IJob | null>>;
}

const formSchema = z
  .object({
    jobDescription: z.string().optional(),
    files: z.array(z.instanceof(File)).optional(),
  })
  .superRefine((data, ctx) => {
    const hasDescription = data.jobDescription && data.jobDescription?.length > 0;
    const hasFile = data.files && data.files?.length > 0;

    if (!hasDescription && !hasFile) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["jobDescription"],
        message: "Job description is required",
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["files"],
        message: "Job description file is required",
      });

      return;
    }
  });

const JobDescription: React.FC<Props> = ({ defaultTeam, currentJob, setCurrentJob }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  // refs
  const isEditRef = useRef<boolean>(false);

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // schema
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      jobDescription: "",
      files: [],
    },
  });

  const { watch } = form;
  const watchFiles = watch("files");

  // functions
  const handleCreateJob = async (title: string, employer: string, ref: string, note: string) => {
    const res = await createJobFirstStep(title, employer, ref, note, defaultTeam?.id);
    return res;
  };

  const handleAddDescription = async (des: string) => {
    setIsLoading(true);

    let jobId = currentJob?.id;
    let isNewJob = false;

    if (!jobId) {
      isNewJob = true;
      const newJob = await handleCreateJob("", "", "", "");
      if (!newJob?.id) {
        toast.error("Failed to create job");
        setIsLoading(false);
        return;
      }

      jobId = newJob.id;
    }

    const res = await addDescriptionToJob(jobId, des);
    setIsLoading(false);

    if (res) {
      if (isNewJob) {
        navigate(`/job/${jobId}`);
      } else {
        setCurrentJob({ ...res });
        navigate(`${location.pathname}?step=2`);
      }
    }
  };

  const handleAddFile = async (file: File) => {
    isEditRef.current = true;
    let jobId = currentJob?.id;
    let isNewJob = false;

    dispatch(setLoading(true));

    if (!jobId) {
      isNewJob = true;
      const newJob = await handleCreateJob("", "", "", "");

      if (!newJob?.id) {
        toast.error("Failed to create job");
        dispatch(setLoading(false));
        return;
      }

      jobId = newJob.id;
    }

    const res = await addFileToJob(file, jobId);
    dispatch(setLoading(false));

    if (res) {
      toast.success("File uploaded successfully");
      setCurrentJob({ ...res });
      form.setValue("files", []);
    }

    if (isNewJob) {
      navigate(`/job/${jobId}`);
    }
  };

  const onSubmit = (values: z.infer<ReturnType<typeof jobDescriptionFormSchema>>) => {
    if (values?.jobDescription && values?.jobDescription?.length > 0 && values?.files && values?.files?.length > 0) {
      toast.warning("You can only upload a file or upload text!");
      return;
    }
    const dif = diffWords(currentJob?.description || "", values.jobDescription || "");
    const hasDifferences = dif.some((part) => part.added || part.removed);

    if (hasDifferences) {
      handleAddDescription(values.jobDescription || "");
    } else {
      navigate(`${location.pathname}?step=2`);
    }
  };

  // effects
  useEffect(() => {
    if (currentJob?.description) {
      form.setValue("jobDescription", currentJob.description);
    }
  }, [currentJob]);

  useEffect(() => {
    if (watchFiles && watchFiles.length > 0) {
      handleAddFile(watchFiles[0]);
    }
  }, [watchFiles]);

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full grow relative pb-14"
        >
          <div className="w-full flex flex-col items-center gap-4 rounded-lg py-4 max-w-[450px] mx-auto bg-gray-200/80 dark:bg-black/30 mb-2">
            <div className="space-y-6">
              {currentJob?.desc_url && (
                <div>
                  <ul className="flex flex-wrap gap-x-12 gap-y-4 pl-6">
                    <li className="text-sm list-decimal underline">
                      <a href={currentJob.desc_url}>Job Description File</a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <FormField
              control={form.control}
              name="files"
              render={({ field }) => (
                <FormItem className="">
                  <FormControl>
                    <FileUploader
                      className="mt-0"
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="jobDescription"
            render={({ field }) => (
              <FormItem className="col-span-1 md:col-span-2 lg:col-span-3 space-y-1">
                <FormLabel className="text-black">Job Description text</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Enter your job description here"
                    className="resize-none h-[50vh]"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <p className="text-center text-sm w-full mt-2">
            Upload <strong>Job Description File</strong> or paste&nbsp;
            <strong>Job Description Text</strong>
          </p>

          <div className="absolute bottom-0 right-0 w-full flex justify-end gap-4">
            <Button
              variant="outline"
              onClick={() => navigate(`/job-information/${currentJob?.id}`)}
            >
              Back
            </Button>
            <Button
              type="submit"
              className="flex items-center gap-1"
              disabled={isLoading}
            >
              {isLoading && <Loader2 className="size-4 animate-spin" />}
              <span>Next step</span>
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default JobDescription;
