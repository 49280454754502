import { toast } from "sonner";
import { Dispatch, SetStateAction, useRef, useEffect } from "react";

import SignatureCanvas from "react-signature-canvas";

import { Button } from "src/shadcn/ui/button";
import { IConsent } from "src/models/pay-load";
import { IRefereeCheck } from "src/models";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";

interface Props {
  currentReferee: IRefereeCheck | null;
  setConsent: Dispatch<SetStateAction<IConsent | null>>;
  onNextStep: () => void;
}

const RefereeConsent: React.FC<Props> = ({ currentReferee, setConsent, onNextStep }) => {
  // refs
  const signatureCanvasRef = useRef<SignatureCanvas | null>(null);

  const handleNextStep = () => {
    if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
      toast.error("Please sign your name");
      return;
    }

    const data = signatureCanvasRef.current.toDataURL();
    // Get OS
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const os = platform || userAgent;
    const browserInfo = {
      name: navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)?.[1] || "",
      version: navigator.userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i)?.[2] || "",
    };

    setConsent({
      signature: data || "",
      os,
      ip: "",
      browser: `${browserInfo.name} ${browserInfo.version}`,
    });
    onNextStep();
  };

  // effects
  useEffect(() => {
    if (signatureCanvasRef.current && currentReferee?.consent?.signature) {
      signatureCanvasRef.current.fromDataURL(currentReferee.consent.signature);
    }
  }, [signatureCanvasRef, currentReferee]);

  return (
    <>
      <p className="text-sm text-foreground font-semibold shrink-0">Referee's Consent</p>
      <Card className="grow">
        <CardHeader className="hidden">
          <CardTitle>dddd</CardTitle>
          <CardDescription>asdasd</CardDescription>
        </CardHeader>
        <CardContent className="p-4">
          <p className="text-sm mb-2">
            Hi <strong>{currentReferee?.job?.recruitment_lead}</strong>
          </p>

          <p className="text-sm mb-1">
            I,&nbsp;
            <strong>
              {currentReferee?.referee?.first_name} {currentReferee?.referee?.last_name}
            </strong>
            , hereby give my consent to provide a professional reference for{" "}
            <strong>
              {currentReferee?.candidate?.first_name} {currentReferee?.candidate?.last_name}
            </strong>
            &nbsp;to&nbsp;
            <strong>{currentReferee?.job?.employer}</strong>. I agree to share information regarding the candidate's
            qualifications, work performance, and suitability for employment with your organization.
          </p>
          <p className="text-sm mb-1">
            I understand that the information I provide will be used solely for the purpose of evaluating the candidate
            for employment and will be kept confidential.
          </p>
          <p className="text-sm mb-2">Please feel free to contact me at your convenience.</p>
          <p className="text-sm mb-1">Sincerely,</p>

          <SignatureCanvas
            ref={signatureCanvasRef}
            penColor="green"
            canvasProps={{
              height: 150,
              className: "sigCanvas",
              style: {
                width: "100%",
                maxWidth: 350,
              },
            }}
            backgroundColor="#cfe1b9"
          />
        </CardContent>
      </Card>

      <div className="shrink-0 flex justify-end">
        <Button onClick={handleNextStep}>Next Step</Button>
      </div>
    </>
  );
};

export default RefereeConsent;
