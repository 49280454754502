import { useRef, useEffect } from "react";

import SignatureCanvas from "react-signature-canvas";

interface Props {
  signature: string;
  disabled?: boolean;
}

const ShowSignature = ({ signature, disabled = false }: Props) => {
  const signatureCanvasRef = useRef<SignatureCanvas | null>(null);

  useEffect(() => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.fromDataURL(signature);
    }
  }, [signature]);

  return (
    <div className="relative">
      <SignatureCanvas
        ref={signatureCanvasRef}
        penColor="green"
        canvasProps={{
          height: 150,
          className: "sigCanvas",
          style: {
            zIndex: 5,
            width: "100%",
            maxWidth: 350,
          },
        }}
        backgroundColor="#cfe1b9"
      />

      {disabled && <div className="absolute top-0 left-0 w-full h-full bg-transparent z-10 cursor-not-allowed" />}
    </div>
  );
};

export default ShowSignature;
