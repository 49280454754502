import { Loader2 } from "lucide-react";
import { useState, useRef } from "react";

import { Card } from "src/shadcn/ui/card";
import { Button } from "src/shadcn/ui/button";
import { postSubscribePlan } from "src/services/subscription";
import { BankCardIcon, PaypalIcon } from "src/assets/icons";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "src/shadcn/ui/dialog";

interface Props {
  open: boolean;
  planId: string;
  teamId: string;
  paymentChannel: string;
  setOpen: () => void;
  callback?: () => void;
}

const SubscribeNowModal: React.FC<Props> = ({ open, planId, teamId, paymentChannel, setOpen, callback }) => {
  // refs
  const delayRef = useRef<NodeJS.Timeout | null>(null);

  // states
  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleSubscribe = async () => {
    setIsLoading(true);
    const res = await postSubscribePlan(teamId, planId, `${window.location.origin}/paypal-callback`);

    if (res === "ok") {
      if (delayRef.current) {
        clearTimeout(delayRef.current);
      }

      delayRef.current = setTimeout(() => {
        setIsLoading(false);
        callback?.();
        setOpen();
      }, 5000);
    } else {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
    >
      <DialogContent className="sm:max-w-[425px] p-4">
        <DialogHeader>
          <DialogTitle>Subscribe Plan</DialogTitle>
          <DialogDescription className="hidden">
            Make changes to your payment method here. Click save when you're done.
          </DialogDescription>
        </DialogHeader>
        <div className="w-full">
          {paymentChannel === "Stripe" ? (
            <Card className="p-3">
              <div className="flex items-center justify-between mb-4">
                <BankCardIcon />
                <p className="font-semibold">Stripe</p>
              </div>
              <div className="flex items-center gap-4">
                <Button
                  className="flex-1 flex items-center gap-2"
                  onClick={handleSubscribe}
                  disabled={isLoading}
                >
                  {isLoading && <Loader2 className="size-4 animate-spin" />}
                  <span>Subscribe</span>
                </Button>
                <Button
                  className="flex-1"
                  variant="destructive"
                  onClick={setOpen}
                >
                  Cancel
                </Button>
              </div>
            </Card>
          ) : (
            <Card className="p-3">
              <div className="flex items-center justify-between mb-4">
                <PaypalIcon />
                <p className="font-semibold">Paypal</p>
              </div>
              <div className="flex items-center gap-4">
                <Button
                  className="flex-1 flex items-center gap-2"
                  onClick={handleSubscribe}
                  disabled={isLoading}
                >
                  {isLoading && <Loader2 className="size-4 animate-spin" />}
                  <span>Subscribe</span>
                </Button>
                <Button
                  className="flex-1"
                  variant="destructive"
                  onClick={setOpen}
                >
                  Cancel
                </Button>
              </div>
            </Card>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscribeNowModal;
