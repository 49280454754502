import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { cn } from "src/lib/utils";
import { setError } from "src/store/features/error";
import { RootState } from "src/store/store";
import { Header, Footer } from "src/layouts";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "src/shadcn/ui/dialog";

import LoadingGif from "src/assets/gifs/loading.gif";
import SubscriptionPlanNow from "src/components/features/SubscriptionPlanNow";

type Props = {
  children: React.ReactNode;
};

const VerticalLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.error.error);
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const verticalStepWidth = useSelector((state: RootState) => state.loading.verticalStepWidth);

  // functions
  const handleCloseErrorDialog = useCallback(() => {
    dispatch(setError(null));
  }, []);

  return (
    <div className="min-h-screen flex flex-col mx-auto px-1 md:px-4">
      <Header />
      <main className={cn("flex-1 flex flex-col py-4 md:py-6")}>{children}</main>
      <Footer />

      <Dialog
        open={error?.errorStatus}
        onOpenChange={handleCloseErrorDialog}
      >
        <DialogContent className="w-[80vw] max-w-[80vw]">
          <DialogHeader>
            <DialogTitle>Limit job quota</DialogTitle>
            <DialogDescription>
              You have exceeded your current credit limit. Would you like to upgrade to a new plan?
            </DialogDescription>
          </DialogHeader>
          <SubscriptionPlanNow teamId={error?.teamId || ""} />
        </DialogContent>
      </Dialog>

      {isLoading && (
        <div
          className="h-screen w-screen fixed top-0 left-0 z-50 flex justify-center items-center px-4"
          style={{
            paddingLeft: `${verticalStepWidth + 65}px`,
          }}
        >
          <img
            src={LoadingGif}
            alt="loading"
            className="size-36 object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default VerticalLayout;
