import { toast } from "sonner";
import { diffWords } from "diff";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import { cn } from "src/lib/utils";
import { Label } from "src/shadcn/ui/label";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { setLoading } from "src/store/features/loading";
import { IResumeRound } from "src/models";
import { AttachmentIcon } from "src/assets/icons";
import {
  uploadInterviewFilePerResume,
  updateContentPerInterview,
  addNewContentPerInterview,
  updateInterviewFilePerResume,
} from "src/services/job";

interface Props {
  index: number;
  jobId: string;
  content: string;
  roundId: string;
  interviewId: string;
  isSelected?: boolean;
  resume: IResumeRound;
  callBack?: () => void;
}

const InterviewRoundForm: React.FC<Props> = ({
  index,
  jobId,
  roundId,
  content,
  resume,
  interviewId,
  isSelected,
  callBack,
}) => {
  const dispatch = useDispatch();

  // states
  const [interviewContent, setInterviewContent] = useState<string>("");

  // functions
  const handleUploadInterviewPerResume = async (file: File) => {
    dispatch(setLoading(true));
    const res = await uploadInterviewFilePerResume(jobId, roundId, resume.id, file, "");
    dispatch(setLoading(false));

    if (res) {
      callBack?.();
    }
  };

  const handleUpdateInterviewFilePerResume = async (file: File) => {
    dispatch(setLoading(true));
    const res = await updateInterviewFilePerResume(jobId, roundId, resume.id, interviewId, file);
    dispatch(setLoading(false));

    if (res) {
      callBack?.();
    }
  };

  const handleUpdateContentPerInterview = async (content: string) => {
    dispatch(setLoading(true));
    const res = await updateContentPerInterview(jobId, roundId, resume.id, interviewId, content);
    dispatch(setLoading(false));

    if (res) {
      toast.success("Update content successfully");
      callBack?.();
    }
  };

  const handleAddNewContentPerInterview = async (content: string) => {
    dispatch(setLoading(true));
    const res = await addNewContentPerInterview(jobId, roundId, resume.id, content);
    dispatch(setLoading(false));

    if (res) {
      toast.success("Add new content successfully");
      callBack?.();
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (interviewId) {
        handleUpdateInterviewFilePerResume(file);
      } else {
        handleUploadInterviewPerResume(file);
      }
    }
  };

  const handleContent = () => {
    if (!interviewId) {
      handleAddNewContentPerInterview(interviewContent);
      return;
    }

    const dif = diffWords(content || "", interviewContent || "");
    const hasDifferences = dif.some((part) => part.added || part.removed);

    if (hasDifferences) {
      handleUpdateContentPerInterview(interviewContent);
    }
  };

  // effects
  useEffect(() => {
    setInterviewContent(content);
  }, [content]);

  return (
    <div>
      <div
        className={cn(
          "flex flex-col md:flex-row md:items-center justify-between p-2 rounded-tl-md rounded-tr-md transition-all duration-300 bg-coralGlow",
          isSelected && "bg-green-500 text-white",
        )}
      >
        <div>
          <p className="text-sm font-semibold">
            Candidate {index + 1}: {resume.candidate_details?.first_name} {resume.candidate_details?.last_name}
          </p>
          <p className="text-sm font-semibold">Phone: {resume.candidate_details?.mobile}</p>
        </div>
        <div>
          <p className="text-sm font-semibold">Email: {resume.candidate_details?.email}</p>

          <label
            htmlFor={`upload_applicant_resumes-${resume.id}-${roundId}`}
            className={cn("flex gap-1 items-center cursor-pointer")}
          >
            <input
              id={`upload_applicant_resumes-${resume.id}-${roundId}`}
              type="file"
              className="hidden"
              accept=".pdf,.docs,.docx,.ppt"
              multiple
              onChange={handleChangeFile}
            />
            <div className="hidden md:block">
              <AttachmentIcon />
            </div>
            <div className="">
              <span className="text-sm font-semibold">
                Upload interview video/audio records, or notes in Docs or Docx
              </span>
            </div>
            <div className="md:hidden">
              <AttachmentIcon />
            </div>
          </label>
        </div>
      </div>

      <div className="mt-1">
        <div className="flex justify-between items-end my-2">
          <Label>Interview notes</Label>
          <Button
            size="sm"
            className="bg-[#FF8000] hover:bg-[#FF8000]/75"
            onClick={handleContent}
          >
            {interviewId ? "Update notes" : "Add notes"}
          </Button>
        </div>
        <Textarea
          // disabled={!interviewId}
          value={interviewContent}
          className="min-h-[350px]"
          onChange={(e) => setInterviewContent(e.target.value)}
        />
      </div>
    </div>
  );
};

export default InterviewRoundForm;
