import { useEffect, useState } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

import { Input } from "src/shadcn/ui/input";
import { Label } from "src/shadcn/ui/label";
import { Textarea } from "src/shadcn/ui/textarea";
import { Accordion } from "src/components/common";
import { IReferenceCheck } from "src/models";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";

import ShowSignature from "./ShowSignature";
import ReferenceCheck from "./ReferenceCheck";

interface Props {
  referenceList: IReferenceCheck[];
}

const Reference = ({ referenceList }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const currentReferenceId = searchParams.get("referenceId");
  const step = searchParams.get("step");

  // states
  const [currentReference, setCurrentReference] = useState<IReferenceCheck | null>(null);

  // effects
  useEffect(() => {
    if (!currentReferenceId && referenceList && referenceList?.length > 0 && step === "7") {
      navigate(`${location.pathname}?step=7&referenceId=${referenceList[0].details.id}`);
      return;
    }

    if (currentReferenceId && referenceList && referenceList?.length > 0) {
      const currentReference = referenceList.find((reference) => reference.details.id === currentReferenceId);
      setCurrentReference(currentReference ?? null);
    }
  }, [currentReferenceId, referenceList]);

  console.log(currentReference);

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <p className="text-xl font-bold">Reference Check</p>
      </div>

      {currentReference && <ReferenceCheck currentReference={currentReference} />}

      {currentReference?.referee_consents && currentReference?.referee_consents?.length > 0 && (
        <div className="mt-8 mb-2">
          <p className="text-xl font-bold mb-6">Referee Response</p>
          <Card className="mt-2">
            <CardHeader className="hidden">
              <CardTitle>dddd</CardTitle>
              <CardDescription>asdasd</CardDescription>
            </CardHeader>
            <CardContent className="p-0 space-y-10">
              {currentReference?.referees?.map((referee, index) => {
                return (
                  <div
                    key={`referee-${index}`}
                    className="border-b border-slate-300"
                  >
                    <Accordion
                      titleElement={
                        <div className="w-full relative">
                          <p className="text-base font-semibold dark:text-black text-left">Reference {index + 1}</p>
                          <p className="text-xs sm:text-sm md:text-base font-semibold text-green-500 absolute right-2 top-1/2 -translate-y-1/2">
                            Status:{" "}
                            {currentReference?.referee_consents?.[index]?.signature
                              ? "Reference complete"
                              : "Reference request sent"}
                          </p>
                        </div>
                      }
                      className="mb-2 bg-slate-200 p-2 rounded-tl-md rounded-tr-md"
                    >
                      <div className="grid grid-cols-2 gap-x-8 gap-y-4 p-2">
                        <div>
                          <Label className="text-xs">Full name</Label>
                          <Input
                            disabled
                            value={`${referee?.first_name} ${referee?.last_name}`}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Phone</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Email</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Link profile</Label>
                          <Input
                            disabled
                            value={referee?.mobile}
                          />
                        </div>

                        <div className="col-span-2">
                          <Label className="text-xs">Professional relationship with referee</Label>
                          <Textarea
                            disabled
                            value={referee?.relation}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Start working with referee</Label>
                          <Input
                            disabled
                            value={referee?.start_date}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">End working with referee</Label>
                          <Input
                            disabled
                            value={referee?.end_date}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Company's name</Label>
                          <Input
                            disabled
                            value={referee?.company_name}
                          />
                        </div>

                        <div>
                          <Label className="text-xs">Referee's job tittle</Label>
                          <Input
                            disabled
                            value={referee?.job_name}
                          />
                        </div>

                        {currentReference?.details?.questions && currentReference?.details?.questions?.length > 0 && (
                          <div className="col-span-2">
                            <Label className="text-xs">Response from referee</Label>

                            <div className="space-y-2">
                              {currentReference?.details?.questions.map((question, index) => (
                                <div
                                  key={`${currentReference?.details?.id}-${index}`}
                                  className="bg-slate-200 p-2 rounded-md"
                                >
                                  <Label className="text-xs">
                                    <strong>Question {index + 1}: </strong>
                                    {question}
                                  </Label>
                                  <Textarea
                                    disabled
                                    value={referee?.answers?.[index]}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion>

                    <Accordion
                      titleElement={
                        <div className="">
                          <p className="text-base font-semibold dark:text-black text-left">{`Referee ${
                            index + 1
                          }'s consent`}</p>
                        </div>
                      }
                      className="mb-2 bg-slate-200 p-2"
                    >
                      <div className="p-2">
                        <p className="text-sm mb-2">
                          Hi <strong>{currentReference?.details?.recruitment_lead}</strong>
                        </p>

                        <p className="text-sm mb-1">
                          I,&nbsp;
                          <strong>
                            {referee?.first_name} {referee?.last_name}
                          </strong>
                          , hereby give my consent to provide a professional reference for&nbsp;
                          <strong>
                            {currentReference?.candidate?.first_name} {currentReference?.candidate?.last_name}
                          </strong>
                          &nbsp; to <strong>{currentReference?.details?.employer}</strong>. I agree to share information
                          regarding the candidate's qualifications, work performance, and suitability for employment
                          with your organization.
                        </p>

                        <p className="text-sm mb-1">
                          I understand that the information I provide will be used solely for the purpose of evaluating
                          the candidate for employment and will be kept confidential.
                        </p>

                        <p className="text-sm mb-2">Please feel free to contact me at your convenience.</p>

                        <p className="text-sm mb-1">Sincerely,</p>

                        <ShowSignature
                          signature={currentReference?.referee_consents[index]?.signature}
                          disabled
                        />
                      </div>
                    </Accordion>
                  </div>
                );
              })}
            </CardContent>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Reference;
