import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Dispatch, SetStateAction, useState, useEffect } from "react";

import { cn } from "src/lib/utils";
import { Button } from "src/shadcn/ui/button";
import { updateStep } from "src/store/features/steps";
import { AttachmentIcon } from "src/assets/icons";
import { uploadResumeFile } from "src/services/job";
import { IJob, IResume, IUploadResumeFileType } from "src/models";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "src/shadcn/ui/tab";

import ChatBox from "../CreateCandidateShortlist/ChatBox";
import UploadResumesTable from "../UploadResumes/UploadResumesTable";

interface Props {
  currentJob: IJob;
  currentSocketEvent: IUploadResumeFileType | null;
  resumeFiles: IResume[];
  setCurrentJob: Dispatch<SetStateAction<IJob | null>>;
  onCallBack?: () => void;
}

const CandidateResumes: React.FC<Props> = ({
  currentSocketEvent,
  currentJob,
  resumeFiles,
  setCurrentJob,
  onCallBack,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [numberUploading, setNumberUploading] = useState<{
    uploading: number;
    uploaded: number;
  }>({
    uploading: 0,
    uploaded: 0,
  });

  // functions
  const handleUploadResumesFile = async (files: File[]) => {
    setIsLoading(true);
    const res = await uploadResumeFile(currentJob.id, files, currentJob.team_id);
    setIsLoading(false);

    if (res) {
      onCallBack?.();
    }
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files?.length > 0) {
      const fileArr = Array.from(files);
      setNumberUploading({
        uploading: fileArr?.length ?? 0,
        uploaded: 0,
      });
      handleUploadResumesFile(fileArr);
    }
  };

  const handleNextStep = async () => {
    if (currentJob?.resumes?.length === 0) {
      toast.warning("Please upload a file");
      return;
    }
    navigate(`${location.pathname}?step=5`);
  };

  const handleBack = () => {
    navigate(`${location.pathname}?step=3`);
  };

  // effects
  useEffect(() => {
    if (currentSocketEvent && currentSocketEvent?.type === "Resume") {
      if (currentSocketEvent?.item?.status === "Indexed") {
        setNumberUploading((prev) => ({
          ...prev,
          uploaded: prev.uploaded + 1,
        }));
      }
    }
  }, [currentSocketEvent]);

  useEffect(() => {
    if (resumeFiles && resumeFiles.length > 0) {
      dispatch(updateStep("candidate_resumes"));
    }
  }, [resumeFiles]);

  return (
    <div className="w-full grow relative pb-14">
      <Tabs
        defaultValue="resumes"
        className=""
      >
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger
            value="resumes"
            className="data-[state=active]:bg-[#00afb9] data-[state=active]:text-white"
          >
            Resumes
          </TabsTrigger>
          <TabsTrigger
            value="q_a"
            className="data-[state=active]:bg-[#00afb9] data-[state=active]:text-white"
          >
            Q&A
          </TabsTrigger>
        </TabsList>
        <TabsContent value="resumes">
          <div className="">
            {numberUploading?.uploading > 0 && numberUploading?.uploaded !== numberUploading?.uploading && (
              <p className="text-sm font-medium mb-1 text-green-500">
                Processing {numberUploading?.uploaded} out of {numberUploading?.uploading} resumes
              </p>
            )}
            <UploadResumesTable
              teamId={currentJob.team_id}
              jobId={currentJob.id}
              resumes={resumeFiles}
              currentSocketEvent={currentSocketEvent}
              callBack={() => onCallBack?.()}
            />

            <div className={cn("mt-8 bg-green-500 text-white p-1 rounded-md w-fit", isLoading && "bg-green-500/50")}>
              <label
                htmlFor="upload_resumes_files"
                className={cn("flex gap-1 items-center cursor-pointer", isLoading && "pointer-events-none")}
              >
                <input
                  id="upload_resumes_files"
                  type="file"
                  className="hidden"
                  accept=".pdf,.docs,.docx,.ppt"
                  multiple
                  onChange={handleChangeFile}
                />
                {isLoading ? <Loader2 className="size-4 animate-spin" /> : <AttachmentIcon />}
                <div className="">
                  <span className="text-sm font-semibold">Upload more files</span>
                </div>
              </label>
            </div>
            <p className="font-semibold text-xs mt-1">*File types supported: PDF, DOCX, and DOC</p>
          </div>
        </TabsContent>
        <TabsContent value="q_a">
          <ChatBox
            currentJob={currentJob}
            setCurrentJob={setCurrentJob}
          />
        </TabsContent>
      </Tabs>

      <div className="absolute bottom-0 right-0 flex justify-end gap-4">
        <Button
          variant="outline"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleNextStep}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default CandidateResumes;
