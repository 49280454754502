import { toast } from "sonner";
import { twMerge } from "tailwind-merge";
import { clsx, type ClassValue } from "clsx";

import { store } from "src/store/store";
import { setError } from "src/store/features/error";
import { IInterviewRound, IJob } from "src/models";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleGlobalError = (err: unknown, teamId?: string) => {
  if (err && typeof err === "object" && "response" in err) {
    const response = (err as { response: { status: number; data: { detail?: string } } }).response;

    if (response?.status === 401 || response?.status === 400) {
      toast.error(response?.data?.detail || "Unauthorized");
      return;
    }

    if (response?.status === 406) {
      store.dispatch(
        setError({
          errorStatus: true,
          errorMessage: response?.data?.detail || "Session expired. Please log in again.",
          teamId,
        }),
      );
      return;
    }
  }

  toast.error("There seems to be an issue with getting responses. Apologize for the inconvenience, please try again.");
};

export const getResumeFileName = (pathName: string, teamId: string): string => {
  if (!pathName) return "";

  const pathNameArr = pathName.split("/");
  const currentNameWithTeamId = pathNameArr[pathNameArr.length - 1];

  if (currentNameWithTeamId.includes(teamId)) {
    return currentNameWithTeamId.slice(teamId.length);
  }

  return currentNameWithTeamId;
};

export function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((value, index) => value === arr2[index]);
}

export const checkLastStepJob = (job: IJob) => {
  if (
    (job?.assessment_desire_to_have_criteria || [])?.length > 0 ||
    (job?.assessment_must_have_criteria || [])?.length > 0
  ) {
    return 4;
  } else if (job?.resumes && job.resumes?.length > 0) {
    return 3;
  } else if (job?.description || job?.desc_url) {
    return 2;
  } else {
    return 1;
  }
};

export const getRoundByRoundNumber = (roundNumber: number, roundList: IInterviewRound[]) => {
  if (!roundList || roundList?.length === 0) {
    return undefined;
  }

  const round = roundList.find((r) => r.round === roundNumber);

  return round;
};

export const moveToDivById = (id: string) => {
  const div = document.getElementById(id);
  if (div) {
    const headerOffset = 56;
    const elementPosition = div.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const normalizeStr = (str: string) => {
  return str.toLowerCase().replace(/\s+/g, "_");
};
