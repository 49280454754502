import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import moment from "moment";

import { cn } from "src/lib/utils";
import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { Calendar } from "src/shadcn/ui/calendar";
import { IRefereeCheck } from "src/models";
import { ButtonLoading } from "src/components/common";
import { submitResponseForReferenceCheck } from "src/services/references";
import { Popover, PopoverContent, PopoverTrigger } from "src/shadcn/ui/popover";
import { IConsent, ISubmitResponseForReferenceCheck } from "src/models/pay-load";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

interface Props {
  checkId: string;
  jobId: string;
  token: string;
  currentReferee: IRefereeCheck;
  currentConsent: IConsent | null;
  onCallBack?: () => void;
}

const questionSchema = z.object({
  question: z.string().min(1, { message: "Question is required" }),
  answer: z.string().min(1, { message: "Answer is required" }),
});

const formSchema = z.object({
  refereeFirstName: z.string().min(1, { message: "Referee's name is required" }),
  refereeLastName: z.string().min(1, { message: "Referee's name is required" }),
  phone: z.string().min(1, { message: "Phone is required" }),
  relation: z.string().min(1, { message: "Professional relationship with candidate is required" }),
  startWorking: z.string().min(1, { message: "Start working with the candidate is required" }),
  endWorking: z.string().min(1, { message: "End working with the candidate is required" }),
  companyName: z.string().min(1, { message: "Company name is required" }),
  candidateJob: z.string().min(1, { message: "Candidate's job tittle is required" }),
  questions: z.array(questionSchema),
});

const RefereeResponse: React.FC<Props> = ({ checkId, jobId, token, currentReferee, currentConsent, onCallBack }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      refereeFirstName: "",
      refereeLastName: "",
      phone: "",
      relation: "",
      startWorking: "",
      endWorking: "",
      companyName: "",
      candidateJob: "",
      questions: [],
    },
  });

  const { watch } = form;
  const questionList = watch("questions");

  const [isLoading, setIsLoading] = useState(false);

  // functions
  const handleSendReferenceCheck = async (data: z.infer<typeof formSchema>) => {
    if (!currentConsent) {
      toast.warning("Please consent to the reference check before sending!");
      return;
    }

    const payload: ISubmitResponseForReferenceCheck = {
      consent: currentConsent,
      referee: {
        first_name: data.refereeFirstName,
        last_name: data.refereeLastName,
        mobile: data.phone,
        relation: data.relation,
        start_date: data.startWorking,
        end_date: data.endWorking,
        company_name: data.companyName,
        job_name: data.candidateJob,
        answers: data.questions.map((question) => question.answer),
      },
    };

    setIsLoading(true);
    const res = await submitResponseForReferenceCheck(jobId, checkId, token, payload);
    setIsLoading(false);

    if (res) {
      toast.success("Reference check sent successfully");
      onCallBack?.();
    }
  };

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    // console.log(data);
    handleSendReferenceCheck(data);
  };

  // effects
  useEffect(() => {
    if (currentReferee) {
      form.setValue("refereeFirstName", currentReferee.referee?.first_name ?? "");
      form.setValue("refereeLastName", currentReferee.referee?.last_name ?? "");
      form.setValue("phone", currentReferee.referee?.mobile ?? "");
      form.setValue("relation", currentReferee.referee?.relation ?? "");
      form.setValue("startWorking", currentReferee.referee?.start_date ?? "");
      form.setValue("endWorking", currentReferee.referee?.end_date ?? "");
      form.setValue("companyName", currentReferee.referee?.company_name ?? "");
      form.setValue("candidateJob", currentReferee.referee?.job_name ?? "");

      const questions =
        (currentReferee?.questions ?? []).map((question, index) => ({
          question: question,
          answer: currentReferee?.referee?.answers?.[index] ?? "",
        })) || [];
      form.setValue("questions", questions);
    }
  }, [currentReferee]);

  return (
    <>
      {/* <p className="text-sm md:text-lg text-foreground font-semibold shrink-0">Reference details for</p> */}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="w-full flex flex-col md:grid md:grid-cols-2 gap-x-8 gap-y-4 mb-8">
            <FormField
              control={form.control}
              name="refereeFirstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs">Referee's name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Referee's name"
                      {...field}
                      style={{
                        marginTop: 0,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="refereeLastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs">Referee’s name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Referee’s name"
                      {...field}
                      style={{
                        marginTop: 0,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs">Phone</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Phone"
                      {...field}
                      style={{
                        marginTop: 0,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="col-span-2">
              <FormField
                control={form.control}
                name="relation"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xs">Professional relationship with candidate</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Professional relationship with candidate"
                        {...field}
                        style={{
                          marginTop: 0,
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="startWorking"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-xs">Start working with the candidate</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn("w-full pl-3 text-left font-normal", !field.value && "text-muted-foreground")}
                          style={{
                            marginTop: 2,
                          }}
                        >
                          {field.value ? field.value : <span>Pick a date</span>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto p-0"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={field.value ? moment(field.value, "DD/MM/YYYY").toDate() : undefined}
                        onSelect={(date) => {
                          if (!date) return;

                          const formattedDate = moment(date).format("DD/MM/YYYY");
                          field.onChange(formattedDate);
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="endWorking"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel className="text-xs">End working with the candidate</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn("w-full pl-3 text-left font-normal", !field.value && "text-muted-foreground")}
                          style={{
                            marginTop: 2,
                          }}
                        >
                          {field.value ? field.value : <span>Pick a date</span>}
                          <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto p-0"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={field.value ? moment(field.value, "DD/MM/YYYY").toDate() : undefined}
                        onSelect={(date) => {
                          if (!date) return;

                          const formattedDate = moment(date).format("DD/MM/YYYY");
                          field.onChange(formattedDate);
                        }}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="companyName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs">Company name</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Company name"
                      {...field}
                      style={{
                        marginTop: 0,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="candidateJob"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-xs">Candidate's job tittle</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Candidate's job tittle"
                      {...field}
                      style={{
                        marginTop: 0,
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="col-span-2 space-y-4">
              {questionList &&
                questionList?.length > 0 &&
                questionList.map((_, index) => (
                  <div
                    key={`question-${index}`}
                    className="bg-slate-200 p-2 rounded-md space-y-1"
                  >
                    <p className="text-sm">
                      Question {index + 1}: {questionList[index].question}
                    </p>

                    <FormField
                      control={form.control}
                      name={`questions.${index}.answer`}
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              placeholder="Enter your answer"
                              {...field}
                              style={{
                                marginTop: 0,
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                ))}
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                navigate(`${location.pathname}?check_id=${checkId}&job_id=${jobId}&token=${token}&step=1`);
              }}
            >
              Back
            </Button>
            <ButtonLoading
              isLoading={isLoading}
              type="submit"
              text="Submit"
            />
          </div>
        </form>
      </Form>
    </>
  );
};

export default RefereeResponse;
