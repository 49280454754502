import { z } from "zod";
import { toast } from "sonner";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState, useMemo, useRef } from "react";

import SignatureCanvas from "react-signature-canvas";

import { Input } from "src/shadcn/ui/input";
import { Button } from "src/shadcn/ui/button";
import { Textarea } from "src/shadcn/ui/textarea";
import { normalizeStr } from "src/lib/utils";
import { IReferenceCheck } from "src/models";
import { Accordion, ButtonLoading } from "src/components/common";
import { sendReferenceCheckToCandidate } from "src/services/references";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/shadcn/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";

interface Props {
  currentReference: IReferenceCheck;
}

const EXTRA_QUESTIONS = [
  "Example “What are the three strengths the candidate has?”",
  "Example “What are the candidate's three areas for development?”",
];

const formSchema = z.object({
  recruitmentLead: z.string(),
  email: z.string(),
  employer: z.string(),
  companyLogo: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  candidateEmail: z.string(),
  linkedinProfile: z.string().optional(),
  questionList: z.array(z.string()),
  numberRefereesRequired: z.string(),
});

const ReferenceCheck: React.FC<Props> = ({ currentReference }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      recruitmentLead: "",
      email: "",
      employer: "",
      companyLogo: "",
      firstName: "",
      lastName: "",
      phone: "",
      candidateEmail: "",
      linkedinProfile: "",
      questionList: ["", ""],
      numberRefereesRequired: "0",
    },
  });

  // refs
  const signatureCanvasRef = useRef<SignatureCanvas | null>(null);

  // states
  const [isSending, setIsSending] = useState(false);

  // functions
  const handleSendReferenceCheckToCandidate = async (values: z.infer<typeof formSchema>) => {
    setIsSending(true);
    const res = await sendReferenceCheckToCandidate(
      currentReference.details.job_id,
      currentReference?.details?.id,
      values.questionList,
      Number(values.numberRefereesRequired),
      {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.candidateEmail,
        mobile: values.phone,
        profile_url: values.linkedinProfile ?? "",
      },
    );
    setIsSending(false);

    if (res) {
      toast.success("Reference request sent successfully");
    }
  };

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    handleSendReferenceCheckToCandidate(values);
  };

  // effects
  useEffect(() => {
    if (currentReference) {
      form.setValue("recruitmentLead", currentReference.details.recruitment_lead ?? "");
      form.setValue("email", currentReference.details.employer_email ?? "");
      form.setValue("employer", currentReference.details.employer ?? "");
      form.setValue("firstName", currentReference?.candidate?.first_name ?? "");
      form.setValue("lastName", currentReference?.candidate?.last_name ?? "");
      form.setValue("phone", currentReference?.candidate?.mobile ?? "");
      form.setValue("candidateEmail", currentReference?.candidate?.email ?? "");
      form.setValue("linkedinProfile", currentReference?.candidate?.profile_url ?? "");
      form.setValue("numberRefereesRequired", `${currentReference?.details?.referee_count || 1}`);

      const questionList = currentReference?.details?.questions || ["", ""];
      form.setValue("questionList", questionList);
    }
  }, [currentReference]);

  useEffect(() => {
    if (currentReference) {
      const sign = currentReference?.candidate_consent?.signature ?? "";
      if (sign) {
        signatureCanvasRef.current?.fromDataURL(sign);
      }
    }
  }, [currentReference]);

  // memos
  const referenceStatus = useMemo(() => {
    const normalizedStatus = normalizeStr(currentReference?.details?.status);

    if (normalizedStatus === "complete") {
      return "Complete";
    }

    if (normalizedStatus === "candidate_requested") {
      return "Candidate requested";
    }

    if (normalizedStatus === "initial") {
      return "Reference request not sent";
    }

    if (normalizedStatus === "referee_requested") {
      return "Reference requested";
    }

    return currentReference?.details?.status;
  }, [currentReference]);

  return (
    <Card className="">
      <CardHeader className="p-0">
        <CardTitle className="text-sm text-foreground text-left mb-4 hidden">Employer details</CardTitle>
        <CardDescription className="hidden">
          This is your reference check for the candidate. Please fill in the details below.
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Accordion
              titleElement={
                <div className="">
                  <p className="text-sm md:text-base font-semibold dark:text-black text-left">Employer details</p>
                </div>
              }
              className="mb-2 bg-slate-200 p-2 rounded-tl-md rounded-tr-md -mt-2 -mr-2 flex items-center justify-between"
            >
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 p-2">
                <FormField
                  control={form.control}
                  name="recruitmentLead"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Recruitment Lead</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your recruitment lead"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Email</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your email"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="employer"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Employer</FormLabel>
                      <FormControl>
                        <Input
                          disabled
                          placeholder="Enter your employer"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex items-end gap-2">
                  <div>
                    {currentReference?.details?.company_logo ? (
                      <div className="w-10 h-10 border border-slate-200 rounded-full p-1 overflow-hidden">
                        <img
                          src={currentReference?.details?.company_logo}
                          alt={currentReference?.details?.employer}
                          className="w-full h-auto object-contain"
                        />
                      </div>
                    ) : (
                      <div className="w-10 h-10 bg-slate-200 rounded-full flex items-center justify-center">
                        {currentReference?.details?.employer_email?.split("@")?.[0]?.[0]?.toUpperCase()}
                      </div>
                    )}
                  </div>

                  <div className="h-10 flex items-center">
                    <p className="text-xs font-semibold">{currentReference?.details?.employer || "Company Logo"}</p>
                  </div>
                </div>
              </div>
            </Accordion>

            <Accordion
              titleElement={
                <div className="w-full relative">
                  <p className="text-sm md:text-base font-semibold dark:text-black text-left">Candidates details</p>
                  <p className="text-xs sm:text-sm md:text-base font-semibold text-green-500 absolute right-2 top-1/2 -translate-y-1/2">
                    Status: {referenceStatus}
                  </p>
                </div>
              }
              className="mb-2 bg-slate-200 p-2 relative"
            >
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4 p-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">First Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter first name"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Last Name</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter last name"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Phone</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter phone"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="candidateEmail"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">Candidate Email</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter candidate email"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="linkedinProfile"
                  render={({ field }) => (
                    <FormItem className="space-y-1">
                      <FormLabel className="text-xs">LinkedIn Profile</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter LinkedIn profile"
                          {...field}
                          style={{ marginTop: 0 }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div>
                  <FormField
                    control={form.control}
                    name="numberRefereesRequired"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-xs">Number Referees Required</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value.toString()}
                        >
                          <FormControl>
                            <SelectTrigger
                              style={{
                                marginTop: 0,
                              }}
                            >
                              <SelectValue placeholder="Select a verified email to display" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="0">0</SelectItem>
                            <SelectItem value="1">1</SelectItem>
                            <SelectItem value="2">2</SelectItem>
                          </SelectContent>
                        </Select>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="col-span-1 md:col-span-2 space-y-2">
                  {form.watch("questionList").map((_, index) => (
                    <div
                      key={index}
                      className="bg-slate-200 p-2 rounded-md"
                    >
                      <FormField
                        control={form.control}
                        name={`questionList.${index}`}
                        render={({ field }) => (
                          <FormItem className="space-y-1">
                            <FormLabel className="text-xs">Question {index + 1}</FormLabel>
                            <FormControl>
                              <Textarea
                                placeholder={EXTRA_QUESTIONS?.[index] || `Enter question ${index + 1}`}
                                {...field}
                                style={{ marginTop: 0 }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col md:flex-row gap-2 justify-between mt-4 p-2">
                <Button
                  type="button"
                  className="bg-mintGreen text-white hover:bg-mintGreen/80 transition-all"
                  onClick={() => {
                    form.setValue("questionList", [...form.watch("questionList"), ""]);
                  }}
                >
                  Add more questions
                </Button>
                <ButtonLoading
                  type="submit"
                  isLoading={isSending}
                  text={
                    normalizeStr(currentReference?.details?.status) !== "initial"
                      ? "Re-send reference request"
                      : "Send reference request"
                  }
                  textClassName="font-semibold"
                />
              </div>
            </Accordion>
          </form>
        </Form>

        {currentReference?.candidate_consent?.signature && (
          <div className="">
            <Accordion
              titleElement={
                <div className="">
                  <p className="text-sm md:text-base font-semibold dark:text-black text-left">Candidate's consent</p>
                </div>
              }
              className="mb-2 bg-slate-200 p-2"
            >
              <div className="p-2">
                <p className="text-sm mb-2">
                  Hi <strong>{currentReference?.details?.recruitment_lead}</strong>
                </p>
                <p className="text-sm mb-1">
                  I,&nbsp;
                  <strong>
                    {currentReference?.candidate?.first_name} {currentReference?.candidate?.last_name}
                  </strong>
                  , here by give my consent to <strong>{currentReference?.details?.recruitment_lead}</strong> to contact
                  the&nbsp;
                  <strong>{currentReference?.referees?.length ?? 0}</strong> referees I have provided for the purpose of
                  conducting a reference check related to my application for the position&nbsp;
                  <strong>{currentReference?.details?.title}</strong>
                </p>
                <p className="text-sm mb-1">
                  I understand that the information I provide will be used solely for the purpose of evaluating the
                  candidate for employment and will be kept confidential.
                </p>
                <p className="text-sm mb-2">Please feel free to contact me at your convenience.</p>
                <p className="text-sm mb-1">Sincerely,</p>

                <div className="relative">
                  <SignatureCanvas
                    ref={signatureCanvasRef}
                    penColor="green"
                    canvasProps={{
                      height: 150,
                      className: "sigCanvas",
                      style: {
                        zIndex: 5,
                        width: "100%",
                        maxWidth: 350,
                      },
                    }}
                    backgroundColor="#cfe1b9"
                  />

                  <div className="absolute top-0 left-0 w-full h-full bg-transparent z-10 cursor-not-allowed" />
                </div>
              </div>
            </Accordion>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ReferenceCheck;
