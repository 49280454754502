import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2, Pencil } from "lucide-react";
import React, { useState, useEffect } from "react";

import { Button } from "src/shadcn/ui/button";
import { updateMemberById } from "src/services/member";
import { Card, CardContent } from "src/shadcn/ui/card";
import { createInvitationFormSchema } from "src/models/form-schema";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/shadcn/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "src/shadcn/ui/form";
import { Input } from "src/shadcn/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "src/shadcn/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/shadcn/ui/tooltip";
import { IMember } from "src/models";

interface Props {
  member: IMember;
}

const EditMemberModal = ({ member }: Props) => {
  // state
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof createInvitationFormSchema>>({
    resolver: zodResolver(createInvitationFormSchema),
    defaultValues: {
      email: "",
      role: "",
    },
  });

  // functions
  const handleUpdateMemberById = async (memberId: string, role: string) => {
    setIsLoading(true);
    const res = await updateMemberById(memberId, role);
    setIsLoading(false);

    if (res === "ok") {
      setOpenModal(false);
      form.reset();
    }
  };

  const onSubmit = (values: z.infer<typeof createInvitationFormSchema>) => {
    handleUpdateMemberById(member.id, values.role);
  };

  // effect
  useEffect(() => {
    if (member?.email) {
      form.setValue("email", member.email);
      form.setValue("role", member.role || "member");
    }
  }, [member]);

  return (
    <React.Fragment>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              className="p-1 h-fit"
              onClick={() => setOpenModal(true)}
            >
              <Pencil className="size-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="dark:text-white text-black text-sm">
            Invite a new member to your team
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog
        open={openModal}
        onOpenChange={() => setOpenModal(false)}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-base text-left">Create Invitation</DialogTitle>
          </DialogHeader>
          <Card className="w-full max-w-[450px]">
            <CardContent className="p-4">
              <div className="w-full">
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email *</FormLabel>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="Enter a email address"
                              {...field}
                              disabled
                            />
                          </FormControl>
                          <FormMessage className="text-xs" />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="role"
                      render={({ field }) => (
                        <FormItem className="w-full">
                          <FormLabel>Select role *</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a role" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="admin">Admin</SelectItem>
                              <SelectItem value="member">Member</SelectItem>
                            </SelectContent>
                            <FormMessage className="text-xs" />
                          </Select>
                        </FormItem>
                      )}
                    />

                    <Button
                      type="submit"
                      className="w-full mb-8"
                      disabled={isLoading || !form.formState.isValid}
                    >
                      {isLoading && <Loader2 className="mr-2 size-5 animate-spin" />}
                      Save
                    </Button>
                  </form>
                </Form>
              </div>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default EditMemberModal;
