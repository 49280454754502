import { Loader2, CircleArrowUp } from "lucide-react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import { cn } from "src/lib/utils";

type FileUploaderProps = {
  isLoading?: boolean;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (files: File[]) => void;
};

export const FileUploader = ({ isLoading = false, className, onChange }: FileUploaderProps) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={cn("file-upload w-fit h-full mt-20 p-4", className && className)}
    >
      <input
        type="file"
        {...getInputProps()}
        accept=".pdf,.docs,.docx,.ppt"
      />
      <div className="flex items-center justify-center gap-2">
        <CircleArrowUp className="size-12 text-indigo-500" />
        {isLoading && <Loader2 className="size-6 animate-spin text-green-500" />}
      </div>
      <div className="file-upload_label mt-2">
        <p className="text-sm font-bold">Drop file here</p>
        <p className="text-sm">
          or <span className="font-bold cursor-pointer underline text-indigo-500">Select file</span>
        </p>
      </div>
    </div>
  );
};
